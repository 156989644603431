const restApi = {
  address: '/address',
  code: '/code',
  companyInvoice: '/invoice/company',
  companyInvoiceHistory: '/invoice/company/history',
  companySearch: '/company/search',
  createCompany: '/auth/register',
  cardPayList: '/sales/cardPayList',
  cardPayBillList: '/sales/cardPayBillList',
  createAdOnetime: '/sales/oneTime',
  createAdFixed: '/sales/fixed',
  createAdRandom: '/sales/random',
  dashboard: '/dashboard',
  getAdList: '/sales/list',
  getOutstandingList: '/sales/longOutstanding',
  invoiceEmail: '/invoice/email',
  invoiceIssue: '/invoice/issue',
  invoicePreview: '/invoice/preview',
  invoiceList: '/invoice/list',
  invoiceCheck: '/invoice/issuedInvoiceCheck',
  invoiceReissue: '/invoice/reissue',
  invoiceBulkCheck: '/invoice/bulkIssuedCheck',
  invoicebulkIssue: '/invoice/issueAll',
  invoicebulkCardPaymentIssue: '/invoice/issueAllCardPay',
  invoiceNumberOfIssues: '/invoice/numberOfIssues',
  login: '/auth/login',
  logout: '/auth/logout',
  notes: '/note',
  paymentHistory: '/payment/history',
  payment: '/payment',
  renewal: '/sales/renewal',
  renewalConfirm: '/sales/renewalConfirm',
  renewalConfirmCheck: '/sales/renewalConfirmCheck',
  renewalMonthlyList: '/sales/renewalMonthlyList',
  updateUser: '/User/updateUser',
  salesPeople: '/User/salesPerson',
  salesStatus: '/sales/status',
  subscribers: '/subscriber',
  resetPassword: '/auth/resetPassword',
  reDownloadInvoice: '/invoice/redownload',
  viewInvoice: '/invoice/issueByCompany',
  statsAD: '/stats/advertisement',
  statsPayment: '/stats/payment',
  monthlySales: '/sales/monthlySales',
  statsSales: '/stats/sales',
  statsCollection: '/stats/collection',

  subscriber: '/subscriber',
  subNote: '/subNote',

  stripeCreateCustomer: 'stripe/customer/create',
  stripeClientSecret: '/stripe/customer/secret',
  stripeCustomerCard: '/stripe/customer/card',
  stripeCustomerCardList: '/stripe/customer/card',
  stripeChangeDefaultCard: '/stripe/customer/card/default',
  stripeDeleteCard: '/stripe/customer/card/delete',
  stripePaymentCheckout: 'stripe/payment/checkout',

  getReceiptList: '/Receipt/getReceiptList',
  issueReceipt: '/Receipt/issueReceipt',
  receiptBulkCheck: '/Receipt/bulkIssuedCheck',
  receiptBulkIssue: '/Receipt/issueAllReceipt',
  allCompanyList: '/company/allCompanyList',

  otherRevenue: '/otherRevenue',
  otherRevenueList: '/otherRevenue/list',
  otherRevenueReceipt: '/otherRevenue/receipt',
  
  promotion: '/promotion',
  checkPromotionCompany: '/company/checkPromotionCompany',
  issuePromotionListPdf: 'promotion/issuePdfList'
}

export default restApi;
